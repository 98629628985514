.typography__body-l {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0;
}

@media (max-width: 1919px) {
    .typography__body-l {
        font-size: 28px;
        line-height: 36px;
    }
}

@media (max-width: 1439px) {
    .typography__body-l {
        font-size: 24px;
        line-height: 32px;
    }
}

@media (max-width: 767px) {
    .typography__body-l {
        font-size: 18px;
        line-height: 24px;
    }
}
