.footer__list_type_justify-content {
    display: flex;
}

@media (max-width: 1023px) {
    .footer__list_type_justify-content {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .footer__list_type_justify-content {
        justify-content: flex-start;
    }
}
