.main-services__service-heading {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
}

@media (max-width: 1023px) {
    .main-services__service-heading {
        gap: 2;
    }
}
