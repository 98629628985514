.main-services__typography-grid-first {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
}

@media(max-width: 1023px) {
    .main-services__typography-grid-first {
        grid-column-start: 2;
    }
}

@media (max-width: 767px) {
    .main-services__typography-grid-first {
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
