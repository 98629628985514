.main-team__card {
    display: flex;
    flex-direction: column;
    gap: 28px;
    flex: 0 0 303px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

@media (max-width: 1919px) {
    .main-team__card {
        flex: 0 0 227px;
        gap: 20px;
    }
}

@media (max-width: 1439px) {
    .main-team__card {
        flex: 0 0 158px;
        gap: 20px;
    }
}

@media (max-width: 1023px) {
    .main-team__card {
        width: 100%;
        gap: 12px;
    }
}
