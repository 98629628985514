.contact-us-form__title {
    max-width: 571px;
}

@media (max-width: 1439px) {
    .contact-us-form__title {
        max-width: 402px;
    }
}

@media (max-width: 767px) {
    .contact-us-form__title {
        max-width: 100%;
    }
}
