.project-heading-info__text-container {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
}

@media (max-width: 1023px) {
    .project-heading-info__text-container {
        grid-column-end: 3;
    }
}

@media (max-width: 767px) {
    .project-heading-info__text-container {
        grid-column-end: 2;
    }
}
