.project-heading-info__info-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4px;
    padding-top: 28px;
    border-top: 1px solid var(--divider-border-secondary);
}

@media (max-width: 1919px) {
    .project-heading-info__info-container {
        padding-top: 24px;
    }
}

@media (max-width: 1439px) {
    .project-heading-info__info-container {
        padding-top: 20px;
    }
}

@media (max-width: 1023px) {
    .project-heading-info__info-container {
        column-gap: 2px;
    }
}

@media (max-width: 767px) {
    .project-heading-info__info-container {
        grid-template-columns: 1fr;
        row-gap: 32px;
        padding-top: 0;
        border: none;
    }
}
