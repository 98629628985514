.main-services {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 28px 40px 320px;
}

@media (max-width: 1919px) {
    .main-services {
        padding: 24px 28px 240px;
    }
}

@media (max-width: 1439px) {
    .main-services {
        padding: 20px 28px 180px;
    }
}

@media (max-width: 1023px) {
    .main-services {
        padding: 20px 12px 180px;
    }
}

@media (max-width: 767px) {
    .main-services {
        padding: 8px 12px 140px;
    }
}
