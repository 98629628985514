.project-about__typography-grid-block {
    float: left;
    width: 307px;
    height: 104px;
}

@media (max-width: 1919px) {
    .project-about__typography-grid-block {
        width: 231px;
        height: 80px;
    }
}

@media (max-width: 1439px) {
    .project-about__typography-grid-block {
        width: 162px;
        height: 60px;
    }
}

@media (max-width: 1023px) {
    .project-about__typography-grid-block {
        width: 373px;
    }
}

@media (max-width: 767px) {
    .project-about__typography-grid-block {
        width: 59px;
        height: 36px;
    }
}
