.footer__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

@media (max-width: 1023px) {
    .footer__list {
        gap: 8px;
    }
}
