.footer__image {
    width: 1521px;
    height: 1521px;
    background-image: url(../../../images/footer-image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -65px;
    left: -465px;
}

@media (max-width: 1919px) {
    .footer__image {
        width: 1254px;
        height: 1254px;
        top: -10px;
        left: -395px;
    }
}

@media (max-width: 1439px) {
    .footer__image {
        width: 864px;
        height: 864px;
        top: -8px;
        left: -281px;
    }
}

@media (max-width: 1023px) {
    .footer__image {
        width: 668px;
        height: 668px;
        top: 104px;
        left: -120px;
    }
}

@media (max-width: 767px) {
    .footer__image {
        width: 500px;
        height: 500px;
        top: 181px;
        left: -164px;
    }
}
