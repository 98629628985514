.info-popup {
    z-index: 101;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(29, 32, 35, .4);
    justify-content: flex-end;
}
