.main-experience__info-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
}

@media (max-width: 1439px) {
    .main-experience__info-content {
        column-gap: 144px;
    }
}

@media (max-width: 1023px) {
    .main-experience__info-content {
        column-gap: 72px;
    }
}

@media (max-width: 767px) {
    .main-experience__info-content {
        grid-template-columns: 1fr 1fr;
        column-gap: 2px;
        row-gap: 32px;
    }
}
