.main-team__card_type_sasha {
    margin-left: 312px;
}

@media (max-width: 1919px) {
    .main-team__card_type_sasha {
        margin-left: 236px;
    }
}

@media (max-width: 1439px) {
    .main-team__card_type_sasha {
        margin-left: 166px;
    }
}

@media (max-width: 1023px) {
    .main-team__card_type_sasha {
        margin-left: 0;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

@media (max-width: 767px) {
    .main-team__card_type_sasha {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
}
