.project-heading-info__list {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 5;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1023px) {
    .project-heading-info__list {
        grid-column-start: 3;
        grid-column-end: 6;
    }
}

@media (max-width: 767px) {
    .project-heading-info__list {
        grid-column-start: 2;
        grid-column-end: 3;
    }
}
