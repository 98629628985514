.contact-us-form__close-button {
    width: 36px;
    height: 36px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    color: var(--text-icon-primary);
}

@media (max-width: 1439px) {
    .contact-us-form__close-button {
        width: 32px;
        height: 32px;
    }
}

@media (max-width: 767px) {
    .contact-us-form__close-button {
        width: 20px;
        height: 20px;
    }
}

.contact-us-form__close-button:hover {
    color: var(--accent-hover);
}
