.info-popup__content {
    padding: 40px 40px 80px;
    display: flex;
    background-color: var(--background-primary);
    width: 1000px;
    position: relative;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1919px) {
    .info-popup__content {
        width: 662px;
        padding: 32px 28px 72px;
    }
}

@media (max-width: 1439px) {
    .info-popup__content {
        width: 454px;
        padding: 28px 28px 48px;
    }
}

@media (max-width: 1023px) {
    .info-popup__content {
        width: 100%;
        padding: 24px 12px 48px;
    }
}