.header__content {
    width: calc(100% - 40px * 2);
    max-width: 1840px;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}

@media (max-width: 1919px) {
    .header__content {
        width: calc(100% - 28px * 2);
    }
}

@media (max-width: 1023px) {
    .header__content {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 12px * 2);
    }
}
