.header__couple-link {
    display: flex;
    gap: 100px;
}

@media (max-width: 1919px) {
    .header__couple-link {
        gap: 52px;
    }
}

@media (max-width: 1439px) {
    .header__couple-link {
        gap: 14px;
    }
}
