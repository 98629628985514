.main-projects__text-container_type_margin-third {
    margin-left: 40px;
}

@media (max-width: 1919px) {
    .main-projects__text-container_type_margin-third {
        margin-left: 28px;
    }
}

@media (max-width: 1023px) {
    .main-projects__text-container_type_margin-third {
        margin-left: 12px;
    }
}
