.main-team__team-content {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 52px;
    /* overflow: hidden; */
}

@media (max-width: 1919px) {
    .main-team__team-content {
        padding-top: 24px;
        gap: 40px;
    }
}

@media (max-width: 1439px) {
    .main-team__team-content {
        padding-top: 20px;
        gap: 32px;
    }
}

@media (max-width: 767px) {
    .main-team__team-content {
        padding-top: 8px;
    }
}
