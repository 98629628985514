.main-projects__project-button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 0;
    max-width: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    color: var(--text-icon-primary);
}

.main-projects__project-button span::after {
    background-color: var(--divider-border-primary);
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.main-projects__project-button span:hover::after,
.main-projects__project-button span:focus::after {
    width: 0;
}
