.golden-egg-results__info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1023px) {
    .golden-egg-results__info-container {
        align-items: flex-start;
    }
}
