.typography__input {
    font-size: 19px;
    line-height: 27px;
}

@media (max-width: 1919px) {
    .typography__input {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 767px) {
    .typography__input {
        line-height: 20px;
    }
}
