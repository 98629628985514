.main-services__service {
    padding: 36px 0 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid var(--divider-border-secondary);
}

@media (max-width: 1919px) {
    .main-services__service {
        padding: 28px 0 8px;
    }
}

@media (max-width: 1439px) {
    .main-services__service {
        padding: 24px 0 8px;
        gap: 16px;
    }
}

@media (max-width: 767px) {
    .main-services__service {
        padding: 20px 0 8px;
        gap: 12px;
    }
}
