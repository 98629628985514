.contact-us-form__content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    background-color: var(--background-primary);
    width: 1000px;
    overflow-y: scroll;
}

@media (max-width: 1919px) {
    .contact-us-form__content {
        width: 662px;
        padding: 28px;
        gap: 48px;
    }
}

@media (max-width: 1439px) {
    .contact-us-form__content {
        width: 454px;
        gap: 28px;
    }
}

@media (max-width: 1023px) {
    .contact-us-form__content {
        width: 100%;
        padding: 24px 12px 124px;
    }
}
