.contact-us-form__checkbox-span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--divider-border-secondary);
    border-radius: 100px;
    box-sizing: border-box;
    color: var(--text-icon-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-primary);
}

.contact-us-form__checkbox-span:hover {
    color: var(--accent-hover);
    border: 1px solid var(--accent-hover);
}

.contact-us-form__checkbox-input:checked ~ .contact-us-form__checkbox-span {
    color: var(--accent);
    border: 1px solid var(--accent);
}
