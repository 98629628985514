.main-projects__project-container {
    display: flex;
    flex-direction: column;
    gap: 52px;
}

@media (max-width: 1919px) {
    .main-projects__project-container {
        gap: 40px;
    }
}

@media (max-width: 1439px) {
    .main-projects__project-container {
        gap: 32px;
    }
}

@media (max-width: 1023px) {
    .main-projects__project-container {
        gap: 28px;
    }
}

@media (max-width: 767px) {
    .main-projects__project-container {
        gap: 20px;
    }
}
