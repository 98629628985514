.info-popup__close-button {
    width: 36px;
    height: 36px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    color: var(--text-icon-primary);
    position: absolute;
    top: 40px;
    right: 40px;
}

@media (max-width: 1919px) {
    .info-popup__close-button {
        top: 28px;
        right: 28px;
    }
}

@media (max-width: 1439px) {
    .info-popup__close-button {
        width: 32px;
        height: 32px;
    }
}

@media (max-width: 1023px) {
    .info-popup__close-button {
        top: 24px;
        right: 12px;
    }
}

@media (max-width: 767px) {
    .info-popup__close-button {
        width: 20px;
        height: 20px;
    }
}

.info-popup__close-button:hover {
    color: var(--accent-hover);
}
