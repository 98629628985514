.main-services__service-number {
    width: 150px;
}

@media (max-width: 1919px) {
    .main-services__service-number {
        width: 112px;
    }
}

@media (max-width: 1439px) {
    .main-services__service-number {
        width: 77px;
    }
}

@media (max-width: 1023px) {
    .main-services__service-number {
        width: 122px;
    }
}

@media (max-width: 767px) {
    .main-services__service-number {
        width: 57px;
    }
}
