.main-team__card_type_julia {
    margin-left: 4px;
}

@media (max-width: 1023px) {
    .main-team__card_type_julia {
        margin-left: 0;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
}

@media (max-width: 767px) {
    .main-team__card_type_julia {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 5;
    }
}
