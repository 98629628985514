.header__burger {
    display: none;
    width: 32px;
    height: 32px;
}

@media (max-width: 1023px) {
    .header__burger {
        display: block;
    }
}
