.title-text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 40px * 2);
    margin: 0 auto;
}

@media (max-width: 1919px) {
    .title-text {
        width: calc(100% - 28px * 2);
    }
}

@media (max-width: 1023px) {
    .title-text {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: calc(100% - 12px * 2);
    }
}
