.main-projects__project-container_type_second {
    grid-column-start: 2;
    grid-column-end: 6;
}

@media (max-width: 1023px) {
    .main-projects__project-container_type_second {
        grid-column-start: 1;
        grid-column-end: 7;
    }
}

