.header__circle {
    background-color: var(--text-icon-primary);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: block;
}

@media (max-width: 1919px) {
    .header__circle {
        display: none;
    }
}
