:root {
    --accent: #5025FF;
    --accent-hover: #7937FF;
    --background-primary: #fff;
    --background-secondary: #1d2023;
    --text-icon-primary: #1d2023;
    --text-icon-secondary: #999;
    --text-icon-tertiary: #343639;
    --text-icon-inverted: #fff;
    --divider-border-primary: #1d2023;
    --divider-border-secondary: #eaeaeb;
    --divider-border-inverted: #7351ff;
    --divider-border-tertiary: #676767;
}