.header {
    width: 100%;
    height: 80px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

@media (max-width: 1919px) {
    .header {
        height: 60px;
    }
}

@media (max-width: 1023px) {
    .header {
        height: 68px;
    }
}
