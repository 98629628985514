.footer__links-container {
    display: grid;
    grid-template-columns: 2fr 1fr 3fr;
}

@media (max-width: 1023px) {
    .footer__links-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 767px) {
    .footer__links-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }
}
