.footer {
    padding: 80px 0;
    background-color: var(--accent);
    display: flex;
    flex-direction: column;
    gap: 72px;
    color: var(--text-icon-inverted);
    position: relative;
    overflow: hidden;
}

@media (max-width: 1919px) {
    .footer {
        padding: 64px 0 40px;
        gap: 64px;
    }
}

@media (max-width: 1439px) {
    .footer {
        padding: 64px 0 32px;
        gap: 32px;
    }
}

@media (max-width: 1023px) {
    .footer {
        padding: 80px 0 32px;
        gap: 24px;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 64px 0 40px;
        gap: 32px;
    }
}
