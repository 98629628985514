.main-experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
}

@media (max-width: 1439px) {
    .main-experience__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 120px;
    }
}

@media (max-width: 767px) {
    .main-experience__container {
        gap: 100px;
    }
}
