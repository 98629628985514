.contact-us-form__submit-button {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 160px;
    height: 160px;
    color: var(--accent);
    cursor: pointer;
}

@media (max-width: 1919px) {
    .contact-us-form__submit-button {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 1439px) {
    .contact-us-form__submit-button {
        width: 100px;
        height: 100px;
    }
}

.contact-us-form__submit-button:hover {
    color: var(--accent-hover);
}
