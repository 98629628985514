.main-team {
    padding: 0 40px 240px;
}

@media (max-width: 1919px) {
    .main-team {
        padding: 0 28px 180px;
    }
}

@media (max-width: 1023px) {
    .main-team {
        padding: 0 12px 180px;
    }
}

@media (max-width: 767px) {
    .main-team {
        padding: 0 12px 140px;
    }
}
