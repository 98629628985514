.main-experience {
    padding: 80px 40px 320px;
    display: flex;
    flex-direction: column;
    gap: 240px;
    border-bottom: 1px solid var(--divider-border-secondary);
}

@media (max-width: 1919px) {
    .main-experience {
        padding: 64px 28px 240px;
        gap: 180px;
    }
}

@media (max-width: 1439px) {
    .main-experience {
        padding: 40px 28px 180px;
        gap: 132px;
    }
}

@media (max-width: 1023px) {
    .main-experience {
        padding: 32px 12px 180px;
    }
}

@media (max-width: 767px) {
    .main-experience {
        padding: 20px 12px 160px;
        gap: 140px;
    }
}
