.contact-us-form__name-inputs-container {
    display: flex;
    gap: 24px;
}

@media (max-width: 767px) {
    .contact-us-form__name-inputs-container {
        flex-direction: column;
    }
}
