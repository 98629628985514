.contact-us-form__input {
    padding: 0 0 8px;
    border: none;
    border-bottom: 1px solid var(--divider-border-secondary);
}

::placeholder {
    color: var(--text-icon-primary);
}

:-ms-input-placeholder {
    color: var(--text-icon-primary);
}

.contact-us-form__input:focus {
    color: var(--divider-border-primary);
    border-bottom: 1px solid var(--divider-border-primary);
    outline: none;
}

/* .contact-us-form__input:focus ~ .contact-us-form__input-text,
.contact-us-form__input:not(:placeholder-shown) ~ .contact-us-form__input-text {
    opacity: 1;
} */
