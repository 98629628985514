.main-projects__text-container_type_margin-first {
    margin-left: 40px;
}

@media(max-width: 1919px) {
    .main-projects__text-container_type_margin-first {
        margin-left: 28px;
    }
}

@media(max-width: 1023px) {
    .main-projects__text-container_type_margin-first {
        margin-left: 0;
    }
}
