.footer__typography-second {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
}

@media (max-width: 1023px) {
    .footer__typography-second {
        grid-column-start: 1;
    }
}
