.main-projects__project-content-container-third {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 0 40px;
}

@media (max-width: 1023px) {
    .main-projects__project-content-container-third {
        padding: 0 74px;
    }
}

@media (max-width: 767px) {
    .main-projects__project-content-container-third {
        padding: 0 41px;
    }
}
