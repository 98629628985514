.footer__text-content {
    display: flex;
    flex-direction: column;
    gap: 350px;
    z-index: 2;
    padding: 0 40px;
}

@media(max-width: 1919px) {
    .footer__text-content {
        padding: 0 28px;
    }
}

@media (max-width: 1439px) {
    .footer__text-content {
        gap: 380px;
    }
}

@media (max-width: 1023px) {
    .footer__text-content {
        gap: 268px;
        padding: 0 12px;
    }
}

@media (max-width: 767px) {
    .footer__text-content {
        gap: 154px;
    }
}
