.project__couple-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 767px) {
    .project__couple-images {
        grid-template-columns: 1fr;
    }
}
