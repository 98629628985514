.contact-us-form__radio-button {
    display: inline-block;
}

.contact-us-form__radio-button input[type=radio] {
	display: none;
}

.contact-us-form__radio-button label {
    display: inline-block;
	cursor: pointer;
	padding: 8px 20px;
    color: var(--text-icon-primary);
	border: 1px solid var(--divider-border-secondary);
    border-radius: 100px;
    box-sizing: border-box;
	user-select: none;
    background-color: var(--background-primary);
}

.contact-us-form__radio-button input[type=radio]:checked + label {
    color: var(--accent);
    border: 1px solid var(--accent);
}

.contact-us-form__radio-button label:hover {
	color: var(--accent-hover);
    border: 1px solid var(--accent-hover);
}
 
/* Disabled */
/* .contact-us__radio-button input[type=radio]:disabled + label {
	background: #efefef;
	color: #666;
} */
