.project-heading-info__profile-info {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 7;
    grid-column-end: 11;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4px;
}

@media (max-width: 1023px) {
    .project-heading-info__profile-info {
        column-gap: 2px;
    }
}

@media (max-width: 767px) {
    .project-heading-info__profile-info {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0;
        padding-top: 8px;
        border-top: 1px solid var(--divider-border-secondary);
    }
}
