.header__logo-container {
    width: 52px;
    cursor: pointer;
}

@media (max-width: 1919px) {
    .header__logo-container {
        width: 44px;
    }
}

@media (max-width: 1023px) {
    .header__logo-container {
        width: 35px;
    }
}
