.main-services__content {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-top: 232px;
}

@media (max-width: 1439px) {
    .main-services__content {
        grid-column-start: 1;
        margin-top: 132px;
    }
}

@media (max-width: 1023px) {
    .main-services__content {
        grid-column-start: 1;
        margin-top: 104px;
    }
}

@media (max-width: 767px) {
    .main-services__content {
        grid-row-start: 4;
        grid-row-end: 5;
        margin-top: 80px;
    }
}
