.project-task__text-container {
    display: flex;
    justify-content: center;
    margin-top: 28px;
}

@media (max-width: 1919px) {
    .project-task__text-container {
        margin-top: 24px;
    }
}

@media (max-width: 1439px) {
    .project-task__text-container {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .project-task__text-container {
        margin-top: 16px;
    }
}
