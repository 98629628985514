.golden-egg-results__container {
    width: calc(100% - 40px * 2);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1919px) {
    .golden-egg-results__container {
        width: calc(100% - 28px * 2);
    }
}

@media (max-width: 1023px) {
    .golden-egg-results__container {
        width: calc(100% - 12px * 2);
        flex-direction: column;
        gap: 80px;
    }
}

@media (max-width: 767px) {
    .golden-egg-results__container {
        gap: 40px;
    }
}
