.typography__number {
    font-size: 410px;
    line-height: 410px;
}

@media (max-width: 1919px) {
    .typography__number {
        font-size: 310px;
        line-height: 310px;
    }
}

@media (max-width: 1439px) {
    .typography__number {
        font-size: 220px;
        line-height: 220px;
    }
}

@media (max-width: 767px) {
    .typography__number {
        font-size: 150px;
        line-height: 150px;
    }
}
