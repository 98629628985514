.contact-us-form__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 64px;
}

@media (max-width: 1919px) {
    .contact-us-form__form {
        gap: 48px;
    }
}
