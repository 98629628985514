.typography__h1 {
    font-size: 132px;
    line-height: 104px;
}

@media (max-width: 1919px) {
    .typography__h1 {
        font-size: 104px;
        line-height: 110px;
    }
}

@media (max-width: 1439px) {
    .typography__h1 {
        font-size: 76px;
        line-height: 80px;
    }
}

@media (max-width: 767px) {
    .typography__h1 {
        font-size: 52px;
        line-height: 60px;
    }
}
