.project-task__grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1023px) {
    .project-task__grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .project-task__grid-container {
        grid-template-columns: 1fr;
    }
}
