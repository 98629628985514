.footer__contact-us-button-container {
    display: flex;
}

@media (max-width: 767px) {
    .footer__contact-us-button-container {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
