.main-projects__text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 596px;
}

@media (max-width: 1919px) {
    .main-projects__text-container {
        width: 459px;
        gap: 20px;
    }
}

@media (max-width: 1439px) {
    .main-projects__text-container {
        width: 456px;
    }
}

@media (max-width: 1023px) {
    .main-projects__text-container {
        width: 620px;
        gap: 16px;
    }
}

@media (max-width: 767px) {
    .main-projects__text-container {
        width: 292px;
    }
}
