.main-team__team-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.main-team__team-container::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1023px) {
    .main-team__team-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2px;
        row-gap: 80px;
    }
}

@media (max-width: 767px) {
    .main-team__team-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }
}
