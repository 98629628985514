.contact-us-form__form-content {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

@media (max-width: 1919px) {
    .contact-us-form__form-content {
        gap: 48px;
    }
}
