.project-task__text-container_type_grid-second {
    margin-bottom: 320px;
}

@media (max-width: 1919px) {
    .project-task__text-container_type_grid-second {
        margin-bottom: 240px;
    }
}

@media (max-width: 1439px) {
    .project-task__text-container_type_grid-second {
        margin-bottom: 180px;
    }
}

@media (max-width: 1023px) {
    .project-task__text-container_type_grid-second {
        margin-bottom: 132px;
    }
}

@media (max-width: 767px) {
    .project-task__text-container_type_grid-second {
        grid-row-start: 4;
        grid-row-end: 5;
        margin-bottom: 120px;
    }
}
