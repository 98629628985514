.main-main-screen__typography {
    max-width: 508px;
    text-align: center;
}

@media (max-width: 1919px) {
    .main-main-screen__typography {
        max-width: 414px;
    }
}

@media (max-width: 1439) {
    .main-main-screen__typography {
        max-width: 482px;
    }
}

@media (max-width: 1023px) {
    .main-main-screen__typography {
        max-width: 508px;
    }
}

@media (max-width: 767px) {
    .main-main-screen__typography {
        max-width: 100%;
    }
}
