.project-about__typography-grid-container {
    grid-column-start: 1;
    grid-column-end: 6;
}

@media (max-width: 1023px) {
    .project-about__typography-grid-container {
        grid-column-end: 7;
    }
}
