.project-heading-info__date-info {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 11;
    grid-column-end: 13;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .project-heading-info__date-info {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 8px;
        border-top: 1px solid var(--divider-border-secondary);
    }
}
