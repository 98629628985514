.main-main-screen__logo-content {
    width: 100%;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1919px) {
    .main-main-screen__logo-content {
        padding: 64px 0;
    }
}

@media (max-width: 1439px) {
    .main-main-screen__logo-content {
        padding: 32px 0;
    }
}

@media (max-width: 1023px) {
    .main-main-screen__logo-content {
        padding: 20px 0;
    }
}

@media (max-width: 767px) {
    .main-main-screen__logo-content {
        height: fit-content;
    }
}
