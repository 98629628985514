.info-popup__info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 571px;
}

@media (max-width: 1919px){
    .info-popup__info-container {
        max-width: 546px;
    }
}

@media (max-width: 1439px) {
    .info-popup__info-container {
        max-width: 373px;
    }
}

@media (max-width: 1023px) {
    .info-popup__info-container {
        gap: 16px;
        max-width: 497px;
    }
}

@media (max-width: 767px) {
    .info-popup__info-container {
        max-width: 100%;
    }
}
