.main-main-screen__showreel {
    width: 227px;
    height: 140px;
    padding: 0;
    border: none;
    background-image: url(../../../images/showreel-small.jpg);
    background-position: center;
    background-size: contain;
    transition: all 0.5s linear;
    position: absolute;
    opacity: 0;
}