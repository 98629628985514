.project-task_type_border-none {
    padding: 28px 0 320px;
    display: flex;
    flex-direction: column;
    gap: 132px;
}

@media (max-width: 1919px) {
    .project-task_type_border-none {
        padding: 24px 0 240px;
        gap: 100px;
    }
}

@media (max-width: 1439px) {
    .project-task_type_border-none {
        padding: 20px 0 180px;
        gap: 80px;
    }
}

@media (max-width: 1023px) {
    .project-task_type_border-none {
        padding: 20px 0 132px;
        gap: 64px;
    }
}

@media (max-width: 767px) {
    .project-task_type_border-none {
        padding: 8px 0 120px;
        gap: 32px;
    }
}
