.project__result-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

@media (max-width: 1023px) {
    .project__result-content {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
}

@media (max-width: 767px) {
    .project__result-content {
        gap: 40px;
    }
}
