.project-task__container {
    display: flex;
    flex-direction: column;
    gap: 320px;
}

@media (max-width: 1919px) {
    .project-task__container {
        gap: 240px;
    }
}

@media (max-width: 1439px) {
    .project-task__container {
        gap: 180px;
    }
}

@media (max-width: 1023px) {
    .project-task__container {
        gap: 132px;
    }
}

@media (max-width: 767px) {
    .project-task__container {
        gap: 120px;
    }
}
