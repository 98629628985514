.main-projects__content {
    display: flex;
    flex-direction: column;
    gap: 52px;
    width: 100%;
}

@media (max-width: 1919) {
    .main-projects__content {
        gap: 40px;
    }
}

@media (max-width: 1439) {
    .main-projects__content {
        gap: 32px;
    }
}
