.footer__link {
    color: var(--text-icon-inverted);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 0;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
}

.footer__link span::after {
    background-color: var(--text-icon-inverted);
    display: block;
    content: '';
    height: 1px;
    width: 0;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.footer__link span:hover::after,
.footer__link span:focus::after {
    width: 100%;
}
