.main-services__typography-grid-second {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
}

@media (max-width: 1023px) {
    .main-services__typography-grid-second {
        grid-column-start: 1;
    }
}

@media (max-width: 767px) {
    .main-services__typography-grid-second {
        grid-row-start: 3;
        grid-row-end: 4;
    }
}
