@media (max-width: 1023px) {
    .main-team__card_type_alexa {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
}

@media (max-width: 767px) {
    .main-team__card_type_alexa {
        grid-column-start: 2;
        grid-column-end: 3;
    }
}
