.project-other-projects__title-container {
    padding: 0 40px;
}

@media (max-width: 1919px) {
    .project-other-projects__title-container {
        padding: 0 28px;
    }
}

@media (max-width: 1023px) {
    .project-other-projects__title-container {
        padding: 0 12px;
    }
}
