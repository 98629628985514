.project-heading-info {
    padding: 240px 40px 180px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

@media (max-width: 1919px) {
    .project-heading-info {
        padding: 180px 28px 140px;
        gap: 64px;
    }
}

@media (max-width: 1439px) {
    .project-heading-info {
        padding: 132px 28px 100px;
    }
}

@media (max-width: 1023px) {
    .project-heading-info {
        padding: 120px 12px 100px;
        gap: 48px;
    }
}

@media (max-width: 767px) {
    .project-heading-info {
        padding: 180px 12px 52px;
        gap: 32px;
    }
}
