.contact-us-form__title-container {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .contact-us-form__title-container {
        flex-direction: column-reverse;
        gap: 32px;
    }
}
