.project {
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 80px;
}

@media (max-width: 1919px) {
    .project {
        padding-top: 60px;
    }
}

@media (max-width: 1023px) {
    .project {
        padding-top: 68px;
    }
}
