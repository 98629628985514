.header__link_type_with-circle {
    display: flex;
    gap: 12px;
    align-items: center;
}

@media (max-width: 1919px) {
    .header__link_type_with-circle {
        gap: 0;
    }
}

@media (max-width: 1023px) {
    .header__link_type_with-circle {
        gap: 28px;
    }
}
