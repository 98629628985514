.typography__h2 {
    font-size: 100px;
    line-height: 104px;
}

@media (max-width: 1919px) {
    .typography__h2 {
        font-size: 76px;
        line-height: 80px;
    }
}

@media (max-width: 1439px) {
    .typography__h2 {
        font-size: 52px;
        line-height: 60px;
    }
}

@media (max-width: 767px) {
    .typography__h2 {
        font-size: 30px;
        line-height: 36px;
    }
}
