.info-popup__logo {
    color: var(--accent);
    width: 100px;
    height: 100px;
}

@media (max-width: 1919px) {
    .info-popup__logo {
        width: 80px;
        height: 80px;
    }
}
