.typography__body-m {
    font-size: 25px;
    line-height: 33px;
}

@media (max-width: 1919px) {
    .typography__body-m {
        font-size: 21px;
        line-height: 28px;
    }
}
