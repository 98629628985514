.title-text__container {
    display: flex;
    flex-direction: column;
    gap: 52px;
    grid-column-start: 2;
    grid-column-end: 3;
}

@media (max-width: 1919px) {
    .title-text__container {
        gap: 40px;
    }
}

@media (max-width: 1439px) {
    .title-text__container {
        gap: 32px;
    }
}

@media (max-width: 1023px) {
    .title-text__container {
        gap: 20px;
    }
}
