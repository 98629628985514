.project-other-projects {
    padding: 28px 0 240px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media (max-width: 1919px) {
    .project-other-projects {
        padding: 24px 0 180px;
    }
}

@media (max-width: 1439px) {
    .project-other-projects {
        padding: 20px 0 180px;
        gap: 32px;
    }
}

@media (max-width: 1023px) {
    .project-other-projects {
        padding: 20px 0 132px;
    }
}

@media (max-width: 767px) {
    .project-other-projects {
        padding: 8px 0 140px;
    }
}
