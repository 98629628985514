.main-projects__project-content-container-second {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 1023px) {
    .main-projects__project-content-container-second {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, auto);
        row-gap: 180px;
    }
}

@media (max-width: 767px) {
    .main-projects__project-content-container-second {
        row-gap: 120px;
    }
}
