.main-main-screen {
    padding: 80px 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1919px) {
    .main-main-screen {
        padding: 60px 28px 64px;
    }
}

@media (max-width: 1439px) {
    .main-main-screen {
        padding: 60px 28px 32px;
    }
}

@media (max-width: 1023px) {
    .main-main-screen {
        padding: 68px 12px 20px;
    }
}
