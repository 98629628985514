.main-team__quote {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 80px;
    border-bottom: 1px solid var(--divider-border-secondary);
}

@media (max-width: 1919px) {
    .main-team__quote {
        padding-bottom: 64px;
    }
}

@media (max-width: 1439px) {
    .main-team__quote {
        padding-bottom: 40px;
    }
}
