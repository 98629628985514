.project-about {
    padding: 80px 40px 320px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-bottom: 1px solid var(--divider-border-secondary);
}

@media (max-width: 1919px) {
    .project-about {
        padding: 64px 28px 240px;
    }
}

@media (max-width: 1439px) {
    .project-about {
        padding: 40px 28px 180px;
    }
}

@media (max-width: 1023px) {
    .project-about {
        padding: 32px 12px 132px;
    }
}

@media (max-width: 767px) {
    .project-about {
        padding: 20px 12px 120px;
    }
}
